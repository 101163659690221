import React, { useEffect, useState } from 'react'
import { FilterButton, Flex, IconButton, TableWidget, Text, Token } from '@revolut/ui-kit'
import { CalendarDate, ChevronLeft, ChevronRight, People } from '@revolut/icons'

import { getTeamTimeOffCalendar } from '@src/api/timeOff'
import { EmployeeInterface } from '@src/interfaces/employees'
import { EmployeeTimeOffCalendar } from '@src/features/TimeOffCalendarTable/EmployeeTimeOffCalendar'
import { TableNames } from '@src/constants/table'
import { EmployeeTimeOffRequestsCalendarInterface } from '@src/interfaces/timeOff'
import AdjustableTable from '@components/Table/AdjustableTable'
import {
  useCurrentMonthRow,
  useCurrentWeekRow,
  useTimeOffCalendarControls,
  useTimeOffCalendarFilters,
} from '@src/features/TimeOffCalendarTable/hooks'
import { requestOfReportsEmployeeColumn } from '@src/constants/columns/timeOff'
import { format, isToday } from 'date-fns'
import { useTable } from '@src/components/Table/hooks'
import { PermissionTypes } from '@src/store/auth/types'
import {
  END_DATE_FILTER_KEY,
  START_DATE_FILTER_KEY,
} from '@src/features/TimeOffCalendarTable/constants'
import { TimeOffLargeWeeklyCalendar } from '@src/features/TimeOffCalendarTable/TimeOffLargeWeeklyCalendar'
import { formatPeriod } from '@src/utils/format'

type Props = {
  data: EmployeeInterface
  period: 'Year' | 'Month' | 'Week' | undefined
  canViewPolicy: boolean
  showTeamViewButton?: boolean
  showThisMonthButton?: boolean
  useFiltersFromQuery?: boolean
  canViewSchedule: boolean
}

export const TeamCalendarView = ({
  data,
  period,
  canViewPolicy,
  canViewSchedule,
  showTeamViewButton = true,
  showThisMonthButton = true,
  useFiltersFromQuery,
}: Props) => {
  const [showTeam, setShowTeam] = useState(!showTeamViewButton)

  // TODO (RHR-4551):
  //  According to the visibility rules, we should hide the policy name column based on the PermissionTypes.ViewTimeOffRequestPolicy
  //  permission value, but for now we assume that if a user has PermissionTypes.ViewTimeOffRequests, he is allowed to see the requests' policies as well.
  //  Before we enable correct permission check, this must be fixed on the permission assignment level for all related users/roles.
  const canViewUserPolicy = data.field_options.permissions?.includes(
    PermissionTypes.ViewTimeOffRequests, // PermissionTypes.ViewTimeOffRequestPolicy
  )

  const isWeekView = period === 'Week'
  const showLargeWeeklyCalendar = isWeekView && !showTeam
  const initialFilters = showTeam
    ? []
    : [
        {
          columnName: 'employee_id',
          filters: [{ id: data.id, name: String(data.id) }],
        },
      ]

  const {
    currentDay,
    onClickNextWeek,
    onClickPrevWeek,
    onClickNextMonth,
    onClickPrevMonth,
    onClickToday,
  } = useTimeOffCalendarControls()

  const { startOfWeek, endOfWeek, getFilters } = useTimeOffCalendarFilters(
    isWeekView,
    currentDay,
    useFiltersFromQuery ? undefined : initialFilters,
  )

  const hasTeam = !!data.team?.id

  const table = useTable(
    { getItems: getTeamTimeOffCalendar(data.team?.id) },
    useFiltersFromQuery ? undefined : getFilters(),
    undefined,
    { disable: !hasTeam, disableQuery: !useFiltersFromQuery },
  )

  useEffect(() => {
    table.onFilterChange(getFilters(), undefined, true)
  }, [period, currentDay, showTeam])

  const weekRow = useCurrentWeekRow(
    currentDay,
    [
      {
        ...requestOfReportsEmployeeColumn,
        width: 220,
      },
    ],
    canViewPolicy,
  )

  const monthRow = useCurrentMonthRow(
    currentDay,
    [
      {
        ...requestOfReportsEmployeeColumn,
        width: 220,
      },
    ],
    canViewPolicy,
  )

  const onLargeWeeklyCalendarWeekSwitch = (startDate: Date, endDate: Date) => {
    const startDateFilter = format(startDate, 'yyyy-MM-dd')
    const endDateFilter = format(endDate, 'yyyy-MM-dd')

    table.onFilterChange([
      {
        filters: [
          {
            id: startDateFilter,
            name: startDateFilter,
          },
        ],
        columnName: START_DATE_FILTER_KEY,
        nonResettable: true,
      },
      {
        filters: [
          {
            id: endDateFilter,
            name: endDateFilter,
          },
        ],
        columnName: END_DATE_FILTER_KEY,
        nonResettable: true,
      },
    ])
  }

  const filterButtons = (
    <>
      <TableWidget.Filters>
        {showThisMonthButton && !showLargeWeeklyCalendar && (
          <FilterButton
            useIcon={CalendarDate}
            onClick={onClickToday}
            active={isToday(currentDay)}
          >
            This {isWeekView ? 'week' : 'month'}
          </FilterButton>
        )}
        {showTeamViewButton && hasTeam && (
          <FilterButton
            useIcon={People}
            onClick={() => setShowTeam(!showTeam)}
            active={showTeam}
          >
            Show team
          </FilterButton>
        )}
      </TableWidget.Filters>
    </>
  )

  if (period === 'Week' || period === 'Month') {
    return (
      <>
        {showLargeWeeklyCalendar ? (
          <TableWidget.Table>
            <TimeOffLargeWeeklyCalendar
              data={table.data[0]}
              employeeId={data.id}
              canViewPolicy={canViewUserPolicy}
              canViewSchedule={canViewSchedule}
              onWeekSwitch={onLargeWeeklyCalendarWeekSwitch}
              side={filterButtons}
            />
          </TableWidget.Table>
        ) : (
          <>
            <TableWidget.Filters>{filterButtons}</TableWidget.Filters>
            <TableWidget.Table>
              <Flex
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                py="s-20"
              >
                <IconButton
                  useIcon={ChevronLeft}
                  color={Token.color.greyTone50}
                  onClick={isWeekView ? onClickPrevWeek : onClickPrevMonth}
                  aria-label={isWeekView ? 'Previous week' : 'Previous month'}
                />
                {isWeekView ? (
                  <Text variant="h5">{formatPeriod(startOfWeek, endOfWeek)}</Text>
                ) : (
                  <Text variant="h5">{format(currentDay, 'MMMM yyyy')}</Text>
                )}
                <IconButton
                  useIcon={ChevronRight}
                  color={Token.color.greyTone50}
                  onClick={isWeekView ? onClickNextWeek : onClickNextMonth}
                  aria-label={isWeekView ? 'Next week' : 'Next month'}
                />
              </Flex>
              <AdjustableTable<EmployeeTimeOffRequestsCalendarInterface>
                name={TableNames.TeamTimeOffCalendar}
                useWindowScroll
                {...table}
                row={isWeekView ? weekRow : monthRow}
                enableSettings={false}
                hideCountAndButtonSection
                rowHeight="large"
              />
            </TableWidget.Table>
          </>
        )}
      </>
    )
  }
  return (
    <EmployeeTimeOffCalendar employeeId={data.id} canViewPolicy={canViewUserPolicy} />
  )
}
